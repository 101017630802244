.containerManifiesto {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
  align-items: center;
  align-content: stretch;
}

@media (max-width: 900px) {
  .containerManifiesto {
    flex-flow: column wrap;
    justify-content: space-around;
    align-items: center;
    align-content: stretch;
  }
}
