/* ul {
  list-style: none;
  padding: 0;
}

a {
  text-decoration: none;
}

.menu-container {
  position: relative;
  display: flex;
  justify-content: space-around;
}
.menu-trigger img {
  position: absolute;
  min-height: 100px;
  overflow: hidden;
  cursor: pointer;
  float: right;
  margin-top: 10%;
  margin-left: 10%;
}

.dropdown-menu {
  position: absolute;
  min-width: 370px;
  float: right;
  margin-top: 15%;
  margin-left: 45%;
  text-align: center;
  border-radius: 30px;
  background-color: #c9b6dc;
  z-index: 1;
  display: block;
}

.dropdown-menu::before {
  content: "";
  position: absolute;
  top: -5px;
  right: 20px;
  height: 20px;
  width: 20px;
  transform: rotate(45deg);
}

.dropdown-menu.active {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
  transition: var(--speed) ease;
}

.dropdown-menu.inactive {
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  transition: var(--speed) ease;
}

h3 {
  width: 100%;
  text-align: center;
  font-size: 18px;
  padding: 20px 0;
  font-weight: 500;
  font-size: 18px;
  color: black;
  line-height: 1.2rem;
}

h3 span {
  font-size: 14px;
  color: black;
  font-weight: 400;
}

.dropdown-menu ul li {
  padding: 10px 0;
}

.dropdown-menu ul li:hover a {
  color: #4a1e7f;
  cursor: pointer;
}

.dropdownItem.darkViolet {
  display: flex;
  background-color: #c9b6dc;
  border-radius: 30px;
}

.dropdownItem.lightViolet {
  display: flex;
  background-color: #e9ddf5;
}

.dropdownItem img {
  max-width: 20px;
  margin-right: 10px;
  opacity: 0.5;
  transition: var(--speed);
}

.dropdownItem a {
  max-width: 300px;
  margin: 0 auto;
  transition: var(--speed);
  color: black;
}

.menu-img-download {
  width: 25%;
} */

/* .dropdown button {
  background-image: url("../../images/boton-descarga.svg");
  background-repeat: no-repeat;
  background-size: cover;
} */
.menuContainer {
  position: absolute;
  margin-right: 10vw;
}
button.dropdown-toggle::after {
  display: none;
}
.buttonImage {
  height: auto;
  width: auto;
  /* display: block; */
}

button.btn-primary,
button.btn {
  /* background-color: transparent; */
  border: transparent;
  border-radius: 50px;
  background-image: url("../../images/boton-descarga.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  min-width: 260px;
  min-height: 70px;
}

button.btn-primary:hover {
  background-color: transparent;
  border: transparent;
}
/* 
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.btn-primary.active-bg {
  background-color: transparent !important;
  border: transparent !important;
}

.btn:focus,
.btn:active,
.btn.active,
.btn.active-bg {
  background-color: transparent !important;
  border: transparent !important;
} */

.dropdown-item {
  font-size: 1rem;
}
.dropdown-item.darkViolet {
  background-color: #c9b6dc;
}

.dropdown-item.lightViolet {
  background-color: #e9ddf5;
}

.dropdown-item.darkViolet:hover {
  background-color: #c9b6dc;
  color: #693f93;
}

.dropdown-item.lightViolet:hover {
  background-color: #e9ddf5;
  color: #693f93;
}

.dropdown-menu {
  background-color: #c9b6dc !important;
  margin: 2%;
}

@media (max-width: 900px) {
  .menuContainer {
    position: absolute;
    left: 45%;
    margin-right: 0px;
  }
  button.btn-primary,
  button.btn {
    min-width: 160px;
    min-height: 50px;
  }

  .dropdown-menu {
    background-color: #c9b6dc !important;
    margin: 0 auto;
    max-width: 100%;
    max-height: 500px;
    overflow: scroll;
  }
  .dropdown-item,
  .dropdown-item:hover {
    font-size: 1rem;
    display: inline-block;
    white-space: normal !important;
  }
}
