/* .wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-row-gap: 80px;
  grid-column-gap: 20px;
  margin: 5%;
}

.one {
  grid-column: 1;
  grid-row: 1;
}
.two {
  grid-column: 2;
  grid-row: 1;
}
.three {
  grid-column: 3;
  grid-row: 1;
}
.four {
  grid-column: 4;
  grid-row: 1;
}
.five {
  grid-column: 1;
  grid-row: 2;
}
.six {
  grid-column: 2;
  grid-row: 2;
}
.seven {
  grid-column: 3;
  grid-row: 2;
}
.eight {
  grid-column: 4;
  grid-row: 2;
}

@media (max-width: 900px) {
  .wrapper {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-row-gap: 40px;
    margin: 2%;
  }

  .one {
    grid-column: 1;
    grid-row: 1;
  }
  .two {
    grid-column: 1;
    grid-row: 2;
  }
  .three {
    grid-column: 1;
    grid-row: 3;
  }
  .four {
    grid-column: 1;
    grid-row: 4;
  }
  .five {
    grid-column: 1;
    grid-row: 5;
  }
  .six {
    grid-column: 1;
    grid-row: 6;
  }
  .seven {
    grid-column: 1;
    grid-row: 7;
  }
  .eight {
    grid-column: 1;
    grid-row: 8;
  }
} */

.wrapper {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  gap: 80px 30px;
  margin: 5%;
}
/* 
.wrapper > div {
  max-width: 25%;
} */
/* 
.texto-marzo {
  min-height: 450px;
  min-width: 360px;
  max-width: 360px;
  max-height: 450px;
}

.one {
  order: 1;
  flex-grow: 0;
  flex-basis: 1em;
  text-align: center;
} */

.wrapper > div {
  max-width: 400px;
}
.one {
  order: 1;
  flex-grow: 1;
  flex-basis: 1em;
}
.two {
  order: 2;
  flex-grow: 1;
  flex-basis: 1em;
}
.three {
  order: 3;
  flex-grow: 1;
  flex-basis: 1em;
}
.four {
  order: 4;
  flex-grow: 1;
  flex-basis: 1em;
}
.five {
  order: 5;
  flex-grow: 1;
  flex-basis: 1em;
}
.six {
  order: 6;
  flex-grow: 1;
  flex-basis: 1em;
}
.seven {
  order: 7;
  flex-grow: 1;
  flex-basis: 1em;
}

@media (max-width: 900px) {
  .wrapper {
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: stretch;
    align-content: center;
    gap: 30px 30px;
    /* margin: 17%; */
  }

  .wrapper > div {
    max-width: none;
  }

  .one {
    order: 1;
    flex-grow: 1;
    flex-basis: 2em;
  }
  .two {
    order: 2;
    flex-grow: 1;
    flex-basis: 2em;
  }
  .three {
    order: 3;
    flex-grow: 1;
    flex-basis: 2em;
  }
  .four {
    order: 4;
    flex-grow: 1;
    flex-basis: 2em;
  }
  .five {
    order: 5;
    flex-grow: 1;
    flex-basis: 2em;
  }
  .six {
    order: 6;
    flex-grow: 1;
    flex-basis: 2em;
  }
  .seven {
    order: 7;
    flex-grow: 1;
    flex-basis: 2em;
  }
}

@media (min-width: 901px) and (max-width: 1200px) {
  .wrapper > div {
    max-width: 50%;
    max-height: 50%;
  }

  .wrapper {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    gap: 80px 30px;
    margin: 5%;
  }
  .wrapper > div {
    max-width: 400px;
  }
  .one {
    order: 1;
    flex-grow: 1;
    flex-basis: 1em;
  }
  .two {
    order: 2;
    flex-grow: 1;
    flex-basis: 1em;
  }
  .three {
    order: 3;
    flex-grow: 1;
    flex-basis: 1em;
  }
  .four {
    order: 4;
    flex-grow: 1;
    flex-basis: 1em;
  }
  .five {
    order: 5;
    flex-grow: 1;
    flex-basis: 1em;
  }
  .six {
    order: 6;
    flex-grow: 1;
    flex-basis: 1em;
  }
  .seven {
    order: 7;
    flex-grow: 1;
    flex-basis: 1em;
  }
}
