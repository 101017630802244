.descargasApp {
  position: relative;
}
.fondoDescargas {
  z-index: -99;
}
.descargaAppStore {
  position: absolute;
  top: 40%;
  right: 10%;
  width: 15%;
}
.descargaPlayStore {
  position: absolute;
  top: 40%;
  right: 30%;
  width: 15%;
}

.botonesDescarga {
  display: flex;
  flex-flow: row wrap;
}
