/* .wrapper-grilla {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 50px;
  grid-auto-rows: minmax(100px, auto);
}

.one-grilla {
  grid-column: 1;
  grid-row: 1;
  font-size: 1.7rem;
  font-family: "Saira", sans-serif;
  color: black;
  width: 100%;
}
.two-grilla {
  grid-column: 1;
  grid-row: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: -8px;
}
.img-grilla {+
  position: absolute;
  z-index: -99;
} */

.wrapper-cards {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-content: stretch;
  justify-content: flex-end;
  min-height: 450px;
  min-width: 360px;
  /* max-width: 360px;
  max-height: 450px; */
  /* margin: 5%; */
}
/* .upper-space {
  margin-bottom: 60%;
  height: 50%;
  width: 100%;
  flex: 3 1 auto;
  align-self: stretch;
} */

.marginTop {
  margin-top: 60%;
  display: flex;
  flex-direction: column;
  align-self: stretch;
}
.one-card {
  padding-left: 5%;
  font-size: 2rem;
  font-family: "Saira", sans-serif;
  color: black;
  flex: 2 0 auto;
  align-self: stretch;
  position: relative;
}

.two-card {
  padding: 0.3rem;
  width: 100%;
  flex: 0 1 auto;
  align-self: center;
  position: relative;
}

.img-card {
  position: absolute;
  z-index: -99;
  width: 100%;
  height: 100%;
}

@media (max-width: 900px) {
  .one-card {
    font-size: 1.5rem;
  }
  .wrapper-cards {
    /* margin: 2%; */
    min-height: 360px;
    max-width: 360px;
  }

  .marginTopMobile {
    margin-top: 50%;
    display: flex;
    flex-direction: column;
    align-self: stretch;
  }
}
